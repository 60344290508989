const moment = require('moment');

function pathTo(resource) {
  switch (resource.__typename) {
    case 'ContentfulNews':
      const date = moment(resource.publishAt).format('YYYY/MM/DD');

      return `/news/${date}/${resource.slug}`;
    case 'ContentfulPage':
      return resource.slug === '/' ? '/' : `/${resource.slug}`;
    default:
      return resource.slug === '/' ? '/' : `/${resource.slug}`;
  }
}

module.exports.pathTo = pathTo;
