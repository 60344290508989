import React from "react";
import { Link, StaticQuery, graphql } from 'gatsby';

import { pathTo } from '../../routes';
import styles from './index.module.scss';
import logo from '../../images/logo-x.svg';

class Header extends React.Component {
  render() {
    return (
      <header className={styles.header}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <Link to='/'>
              <img src={logo} alt='Logo UNKNOWN X'/>
            </Link>
          </div>
          <StaticQuery
            query={graphql`
              {
                allContentfulNavigationZone(filter: { identifier: { eq: "Main" } }, limit: 1000) {
                  edges {
                    node {
                      identifier
                      items {
                        __typename
                        contentfulId: contentful_id
                        title
                        slug
                      }
                    }
                  }
                }
              }
            `}
            render={data => {
              const zone = data.allContentfulNavigationZone && data.allContentfulNavigationZone.edges[0].node;

              if (!zone) {
                return null;
              }

              const items = [...zone.items].map(item => {
                if (item === null || item === undefined) { return null };

                return (
                  <Link key={item.contentfulId} to={pathTo(item)} className={styles.link}>{item.title}</Link>
                )
              });

              return (
                <div className={styles.navigation}>
                  {items}
                </div>
              )
            }}
          />
        </div>
      </header>
    )
  }
}

export default Header;
