import React from "react";
import { Link, StaticQuery, graphql } from 'gatsby';

import { pathTo } from '../../routes';
import TextRenderer from '../text/renderer';
import styles from './index.module.scss';

class Footer extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            allContentfulNavigationZone(filter: { identifier: { eq: "Footer" } }, limit: 1000) {
              edges {
                node {
                  identifier
                  items {
                    __typename
                    contentfulId: contentful_id
                    title
                    slug
                  }
                }
              }
            }
            site {
              siteMetadata {
                copyright
              }
            }
          }
        `}
        render={data => {
          const zone = data.allContentfulNavigationZone.edges[0].node;

          const items = zone.items.map(item => (
            <Link key={item.contentfulId} to={pathTo(item)}>{item.title}</Link>
          ));

          return (
            <footer className={styles.footer}>
              <div className={styles.copyright}>
                <TextRenderer text={data.site.siteMetadata.copyright}/>
              </div>
              <div className={styles.navigation}>
                {items}
              </div>
            </footer>
          )
        }}
      />
    )
  }
}

export default Footer;
